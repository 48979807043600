import React from 'react'
import { graphql } from 'gatsby'
import { object } from 'prop-types'

import { LinkBuilding } from '@jelly/templates'

const Index = ({ data: { props }, pageContext: { canonical } }) => {
	// Take the path from thumbnail and assign it to meta.ogImage to fix the issue with social sharing for this template type.
	props.meta.ogImage = props.thumbnail.path
	props.meta.canonical = canonical
	props.canonical = canonical
	props.author.slug = props.author.user?.slug || props.author.slug
	const authorImagePath = props.author.user?.imagePath.includes('undefined') ? null : props.author.user?.imagePath
	props.author.thumbnail = props.author.thumbnail || props.author.avatar || authorImagePath

	return <LinkBuilding {...props} />
}

Index.propTypes = { data: object, pageContext: object }

export default Index

export const GatsbyQuery = graphql`
	query articleLinkBuilding($slug: String!, $preview: Boolean) {
		# We always need to return props to ve injected on the component
		props: article(slug: $slug, preview: $preview, cache: false) {
			byLine
			docType
			guid
			title
			subtitle
			content
			canonical
			published
			template
			type
			lastModified: displayModified
			author {
				avatar
				bio
				# displayName is coming straight from mongo but we need "name" everywhere in the code
				name: displayName
				image
				slug
				social {
					facebook
					linkedin
					twitter
					website
				}
				status
				title
				type
				user {
					imagePath
					profile {
						company
						jobTitle
						website
					}
					slug
				}
			}
			parsedContent {
				toc {
					id
					title
				}
				json
			}
			meta {
				robots
				description
				title
			}
			thumbnail {
				path
				caption
				isBlurred
			}
		}
	}
`
